import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from '@app/core/model/Subscription';
import { Observable } from 'rxjs';
import { SubscriptionPlan } from '@app/core/model/Plan';
import { GeneralObject } from '@app/core/model/GeneralObject';

import { Paginated } from '../../model/Paginated';

import { MapperService } from './mapper.service';

@Injectable({ providedIn: 'root' })
export class SubscriptionsService extends MapperService<Subscription> {
  constructor(http: HttpClient) {
    super('subscriptions', http);
  }

  public getSubscriptions(params?: GeneralObject): Observable<Paginated<Subscription>> {
    return this.list('')(params);
  }

  public updateSubscription(id: string, body: object): Observable<Subscription> {
    return this.update()(id, body);
  }

  public createSubscription(body: any) {
    return this.create('')(body);
  }

  public buildSubscriptionBody(plan: SubscriptionPlan): any {
    const planIds = plan.planIds;
    const entries = planIds.map((subPlan, i) => ({
      product: {
        _id: subPlan
      },
      ...(i < planIds.length - 1 ? { quantity: 1 } : {})
    }));
    return {
      entries,
      meta: {}
    };
  }
}
